.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(-225deg, #231557 0%, #44107a 29%, #ff1361 67%, #fff800 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 18px !important;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.container1 {
  max-width: 800px;
  background: #fff;
  margin: 0px auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 40px;
  margin-top: 50px;
  text-align: center;
}
.header-nav .show > .nav-link,
.header-nav .nav-link.active {
  color: white !important;
}
.sidebar-nav {
  background-color: #000 !important;
}
.sidebar {
  background-color: #000 !important;
}
.css-13lalgr-MuiCardHeader-root {
  background-color: #000 !important;
  color: white !important;
}
.bg-light {
  background-color: #1e1e1e !important;
}
.css-1t6e9jv-MuiCardActions-root {
  background-color: #1e1e1e !important;
}
.card_st {
  -webkit-box-shadow: 2px 4px 5px 1px rgba(0, 0, 0, 0.47) !important;
  box-shadow: 2px 2px 4px 2px rgba(201, 201, 201, 0.47) !important;
}
.css-46bh2p-MuiCardContent-root {
  background-color: #1e1e1e !important;
  color: white !important;
  // -webkit-box-shadow: 2px 4px 5px 1px rgba(0, 0, 0, 0.47);
  // box-shadow: 2px 4px 5px 10px rgba(201, 201, 201, 0.47);
}
.footer {
  background-color: #1e1e1e !important;
  border-color: #1e1e1e !important ;
}
.container1 * {
  text-align: left;
  /* Правило для дочерних элементов */
}

.header {
  margin-bottom: 30px;
}
.bg-dark {
  background-color: black !important;
}
.header .full-name {
  font-size: 40px;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: #e41e49;
}

.header .first-name {
  font-weight: 700;
}

.header .last-name {
  font-weight: 300;
}

.header .contact-info {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}
.edit_field {
  // border: 1px solid rgb(130, 125, 125);
  border: 1px solid #ff6800 !important;

  background-color: transparent;
  color: #000;
  width: auto;
  border-radius: 3px;
}
.edit_field2 {
  border: 1px solid rgb(130, 125, 125);
  background-color: transparent;
  color: #000;
  width: 70px;
  border-radius: 3px;
}
.header .email,
.header .phone {
  color: #e41e49;
  font-weight: 300;
}

.header .separator {
  height: 10px;
  display: inline-block;
  border-left: 2px solid #494732;
  margin: 0px 10px;
}

.header .position {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
  text-decoration: underline;
}

.details {
  line-height: 20px;
}

.details .section {
  margin-bottom: 40px;
}

.details .section:last-of-type {
  margin-bottom: 0px;
}

.details .section__title {
  letter-spacing: 2px;
  color: #e41e49;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.details .section__list-item {
  margin-bottom: 40px;
}

.details .section__list-item:last-of-type {
  margin-bottom: 0;
}

.details .left,
.details .right {
  vertical-align: top;
  display: inline-block;
}

.details .left {
  width: 60%;
}

.details .right {
  text-align: right;
  width: 39%;
}

.details .name {
  font-weight: bold;
}

.details a {
  text-decoration: none;
  color: #000;
  font-style: italic;
}

.details a:hover {
  text-decoration: underline;
  color: #000;
}

.details .skills__item {
  margin-bottom: 10px;
}
.solid_st {
  border: 2px solid black;
  border-radius: 10px;
}
.details .skills__item .right input {
  display: none;
}

.details .skills__item .right label {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #c3def3;
  border-radius: 20px;
  margin-right: 3px;
}

.details .skills__item .right input:checked + label {
  background: #79a9ce;
}

.phone-hidden {
  display: none;
}

.last-name {
  color: #333333;
}

.desc {
  width: 100% !important;
}
.ant-modal .ant-modal-content {
  z-index: 20000000;
}
.ant-modal {
  z-index: 20000000;
}
.sidebar.sidebar-fixed {
  z-index: 242 !important;
  // -webkit-box-shadow: 2px 4px 5px 1px rgba(0, 0, 0, 0.47);
  // box-shadow: 2px 4px 5px 10px rgba(201, 201, 201, 0.47);
}
.header.header-sticky {
  z-index: 232 !important;
  background-color: #000 !important;
  border-color: #1e1e1e !important ;
}
.login__st {
  background-color: #1e1e1e !important;
  border-color: #1e1e1e !important ;
  -webkit-box-shadow: 2px 4px 5px 1px rgba(0, 0, 0, 0.47) !important;
  box-shadow: 2px 3px 2px 3px rgba(201, 201, 201, 0.47) !important;
}
.header-toggler {
  color: #fff !important;
}
@media screen and (max-width: 658px) {
  .sidebar.sidebar-fixed {
    z-index: 1242 !important;
  }
  .ant-modal .ant-modal-content {
    z-index: 20000;
  }
  .ant-modal {
    z-index: 20000;
  }
}
.css-1fu7jd5-MuiButtonBase-root-MuiButton-root {
  background-color: #ff6800 !important;
}
.css-1fu7jd5-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #ff6800 !important;
  -webkit-box-shadow: 2px 4px 5px 1px #ff6800 !important;
  box-shadow: 2px 1px 2px 1px rgba(201, 201, 201, 0.47) !important;
}
// .ant-switch .ant-switch-inner {
//   background-color: #ff6800 !important;
// }
.ant-switch.ant-switch-checked .ant-switch-inner {
  background-color: #ff6800 !important;
}
.css-zzcnnq-MuiTypography-root {
  color: white !important;
}
.ant-tour .ant-tour-inner .ant-tour-footer .ant-tour-indicators .ant-tour-indicator-active {
  background-color: #ff6800 !important;
}
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: #ff6800 !important;
  background-color: #ff6800 !important;
}
.ant-btn-primary {
  background-color: #ff6800 !important;
}
.css-1qelgoy {
  background-color: #ff6800 !important;
}
.css-2ozc6w {
  display: flex;
  -webkit-box-align: center;
  color: white !important;
  align-items: center;
  padding: 16px;
  background-color: black !important;
}
.css-1o12k06 {
  color: white !important;
}

.css-1qw96cp {
  background-color: #1e1e1e !important;
  color: white !important;
}
.css-3zukih {
  background-color: #1e1e1e !important;
}

.label___564 {
  color: white !important;
}
.borderstt {
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 5px rgba(130, 130, 130, 0.47);
}
.video_st {
  gap: 40px;
}
.video_bg {
  border-radius: 20px;
  opacity: 0.98;
  margin-top: 20px;
  cursor: pointer;
  width: 40vw;
}
.video_bg:hover {
  opacity: 1;
}
.stylest {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-style: italic;
}

.bgstr {
  background-color: #2d3337 !important;
  color: white !important;
  padding: 30px;
}
.c-app {
  background: linear-gradient(-45deg, #ff6800, #2d3337, #fff, #2d3337);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.hoverEffet:hover {
  color: #ff6800 !important;
}
@media (max-width: 668px) {
  .marginht {
    margin-bottom: 100px !important;
  }
}
